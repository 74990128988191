import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';

import auth from './auth';
import layout from './layout';
import news from './news';
import service from './service';

const _reducers = combineReducers({
  news,
  service,
  auth,
  layout,
});

function reducers(state, action) {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  }
  return _reducers(state, action);
}

export default reducers;