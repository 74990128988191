const initialState = {
  news: [],
  category: []
};
import {
  LOAD_NEWS, LOAD_NEW_CATEGORY
} from '@/constants/ActionTypes';


const news = (state = initialState, { type, payload }) => {
  let newState = Object.assign({}, state);
  switch (type) {
    case LOAD_NEWS:
      return {
        ...newState,
        news: payload
      };
    case LOAD_NEW_CATEGORY:
      return {
        ...newState,
        category: payload
      };
    default:
      return { ...newState };
  }
};



export default news;