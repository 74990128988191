const initialState = {
    services: [],
};
import {
    LOAD_SERVICES
} from '@/constants/ActionTypes';


const service = (state = initialState, { type, payload }) => {
    let newState = Object.assign({}, state);
    switch (type) {
        case LOAD_SERVICES:
            return {
                ...newState,
                services: payload
            };
        default:
            return { ...newState };
    }
};



export default service;