import Head from 'next/head';
export default function MetaSeo(props) {
  const urlSeo = 'https://tcomglobal.com';
  let { url, type, title, description, image, domain, card, keywords } = props;
  if (!url) {
    url = urlSeo;
  }
  if (!type) {
    type = 'website';
  }
  if (!title) {
    title = 'TCOM Global - Công ty công nghệ đa lĩnh vực hàng đầu Việt Nam';
  }
  if (!description) {
    description =
      'TCOM Global is a leading technology company in Vietnam, specializing in Digital Transformation, IT System Consulting, Offshore Services, and Developing cutting-edge technologies such as AIOT and Blockchain';
  }
  if (!image) {
    image = `${urlSeo}/assets/img/tgo/img_seo.png`;
  }
  if (!domain) {
    domain = 'https://tcomglobal.com/';
  }
  if (!card) {
    card = 'summary_large_image';
  }
  if (!keywords) {
    keywords =
      'Leading IT company in Vietnam , Outsourcing IT service in Vietnam, Offshore, Outsourcing, Artificial Intelligence, Blockchain, AR/VR, Livestreaming';
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="news_keywords" content={keywords} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="keywords" content={keywords} />
        <meta name="twitter:card" content={card} />
        <meta property="twitter:domain" content={domain} />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Head>
    </>
  );
}
